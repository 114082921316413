import React, { useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Buttons from "../../../Components/Shared/Button";

import StartupHeader from "../../../Components/StartupHeader";
import VideoEmbed from "./VideoEmbed";
export default function ShowVideo() {
  const history = useHistory();
  const navigateTo = () => history.push(`${process.env.PUBLIC_URL}/register`); //eg.history.push('/login');

  useEffect(() => {
    document.body.classList.add("home-page");

    return function cleanup() {
      document.body.classList.remove("home-page");
    };
  }, []);

  return (
    <React.Fragment>
      <StartupHeader />

      <div className="main-content">
        <div className="main-banner">
          <div className="dashboard-wrapper resumes-page">
            <div className="container">
              <div className="dashboard-box dashboard-col-bg public-wrapper outline-form">
                <Form noValidate>
                  <Row>
                    <Col md={9} className="mx-auto">
                      <h1 className="mb-5">JIN Demo</h1>

                      <VideoEmbed
                        videoUrl={
                          "https://drive.google.com/file/d/1bIYWhEdKYCIvb--8C-Yd5Nxwn-dDpSOj/preview"
                        }
                      />

                      <Row>
                        <Col md={8} className="square-radius mt-5">
                          <Buttons
                            variant="outline-primary"
                            title="Back"
                            type="submit"
                            size="xs"
                            icon="icon icon-arrow-left mr-4"
                            onClick={navigateTo}
                          ></Buttons>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
