import React from "react";
import { ListGroup, Container, Row, Col } from "react-bootstrap";

const VideoEmbed = ({ videoUrl }) => {
  // Function to jump to a specific time in the video
  const jumpToTime = (time) => {
    // TODO: Implement this function
    console.log("Jumping to time: ", time);
  };

  return (
    <Container className="my-5">
      <Row>
        <Col md={12} className="text-center d-flex">
          <iframe
            src={videoUrl}
            width="1280"
            height="1024"
            allow="autoplay"
            style={{ border: "none", maxWidth: "100%", height: "800px" }}
            title="Google Drive Video"
            controls
            autoPlay
          ></iframe>
        </Col>
      </Row>
      <Row className="mt-5">
        {/* Button to jump to a specific time */}
        <Col md={12}>
          <h3 className="mb-4">Jump to Specific Step</h3>
          <ListGroup>
            <ListGroup.Item
              className="py-4 d-flex align-items-center"
              onClick={() => jumpToTime(10)}
            >
              Login as a Job Seeker
            </ListGroup.Item>
            <ListGroup.Item
              action
              className="py-4 d-flex align-items-center"
              onClick={() => jumpToTime(30)}
            >
              <span>Send a Networking Request</span>
            </ListGroup.Item>
            <ListGroup.Item
              className="py-4 d-flex align-items-center"
              action
              onClick={() => jumpToTime(60)}
            >
              <span>View the Networking Request as a Support Team Member</span>
            </ListGroup.Item>
            <ListGroup.Item
              className="py-4 d-flex align-items-center"
              action
              onClick={() => jumpToTime(120)}
            >
              <span>Review the Job Seeker's Resume</span>
            </ListGroup.Item>
            <ListGroup.Item
              className="py-4 d-flex align-items-center"
              action
              onClick={() => jumpToTime(180)}
            >
              <span>Provide Feedback & Contact Details to the Job Seeker</span>
            </ListGroup.Item>
            <ListGroup.Item
              className="py-4 d-flex align-items-center"
              action
              onClick={() => jumpToTime(180)}
            >
              <span>View the Feedback as a Job Seeker</span>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default VideoEmbed;
